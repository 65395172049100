<template>
  <div>
    <headerSection></headerSection>
    <div class="x-main-content" :style="{ 'height':'100%','background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}" ><!--,'background-size':'900px 700px','background-image': `url(${require('../assets/images/Shiba-BG.jpg')})` -->
      <div id="main_content_recommend" class="container">
    <section class="row x-category-index" >
       <div class="col-sm-12 col-lg-12 col-12 recommend_section" style="padding-top:15px;">
            <fieldset class="form-group text-center mb-0 px-1">
                <div class="row mt-2 mb-0 mb-4 justify-content-md-center">
                    <div class="col-sm-12 col-lg-12 col-12" style="padding-top: 0px;">
                        <h3 class="text-center mb-3" style="font-weight: 500;color:#fff;">ประวัติธุรกรรม</h3>
                    </div>
                </div>
            </fieldset>
            <table class="tablecontainer">
              <thead>
                <tr>
                  <th><h1 style="text-align:center">วัน/เวลา</h1></th>
                  <th><h1 style="text-align:center">ประเภท</h1></th>
                  <th><h1 style="text-align:center">จำนวน</h1></th>
                  <th><h1 style="text-align:center">สถานะ</h1></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in history" :key="index">
                  <td style="text-align:center;color:#fff;">{{item.createdateShow}}</td>
                  <td style="text-align:center">&nbsp;&nbsp;<font color="#77C3F4" v-if="item.type=='วงล้อ' || item.type=='รูเล็ต' || item.type=='ฝากต่อเนื่อง' || item.type=='พันธมิตร' || item.type=='คืนยอดเล่น'">{{ item.type }}</font><font color="#f92c6a" v-else-if="item.type=='ถอน'">{{ item.type }}</font><font color="#3B9854" v-else>{{ item.type }}</font></td>
                  <td style="text-align:right;color:#fff;">{{ numberWithCommas(item.qty) }}</td>
                  <td style="text-align:center"><font color="#3B9854" v-if="item.status=='ดำเนินการแล้ว'">✅</font><font color="#f92c6a" v-else-if="item.status=='ยกเลิก'">❌</font><font color="#f92c6a" v-else>⏳</font></td>
                </tr>
              </tbody>
            </table>
        </div>

    </section>
</div>
</div>
<footerSection></footerSection>
</div>
</template>

<script>
// color: #FB667A;
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
import moment from 'moment-timezone'
import _ from 'lodash'
export default {
  name: 'Promotion',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  computed: {
	  user () {
      return this.$store.state.user.user
    }
  },
  data () {
    return {
      history: []
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    numberWithCommas (x) {
      if (!x) x = 0
      return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/')
      }
    }
  },
  async mounted () {
    this.scrollToTop()
    this.$store.commit('user/SET_LOADING', true)
    const user = this.getUserId()
    const history = []
    let lastdate = ''
    await firebase.firestore().collection('history').where('userkey', '==', user.key).orderBy('createdate', 'desc').limit(500).get().then((snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.data().qty) {
          const data = doc.data()
          data.key = doc.id
          data.createdateShow = moment(data.createdate, 'YYYY-MM-DD HH:mm:ss').format('DD MMM,YY HH:mm')
          lastdate = data.createdate
          history.push(data)
        }
      })
    })
    console.log('user.key',user.key);
    await firebase.firestore().collection('spin').where('userkey', '==', user.key).where('createdate', '>=', lastdate).get().then((result) => {
      result.forEach((doc) => {
        let game = 'วงล้อ'
        if (doc.data().game === '3boxs') {
          game = 'เปิดหีบสมบัติ'
        } else if (doc.data().game === 'roulet') {
          game = 'รูเล็ต'
        } else if (doc.data().game === 'checkin') {
          game = 'check-in'
        } else if (doc.data().game === 'code') {
          game = 'code'
        } else if (doc.data().game === 'scratch') {
          game = 'ขูดๆถูๆ'
        } else if (doc.data().game === 'gift') {
          game = 'เปิดกล่องของขวัญ'
        }

        history.push({
          key: doc.id,
          type: game,
          createdate: doc.data().createdate,
          createdateShow: moment(doc.data().createdate, 'YYYY-MM-DD HH:mm:ss').format('DD MMM,YY HH:ss'),
          qty: doc.data().value,
          status: 'ดำเนินการแล้ว'
        })
      })
    })
    await firebase.firestore().collection('continuous_deposit_all').where('userkey', '==', user.key).where('status', '==', true).where('receivedate', '>=', lastdate).get().then((result) => {
      result.forEach((doc) => {
        if(doc.data().receivedate)
        history.push({
          key: doc.id,
          type: 'ฝากต่อเนื่อง',
          createdate: doc.data().receivedate,
          createdateShow: moment(doc.data().receivedate, 'YYYY-MM-DD HH:mm:ss').format('DD MMM,YY HH:ss'),
          qty: doc.data().total,
          status: 'ดำเนินการแล้ว'
        })
      })
    })
    await firebase.firestore().collection('partner_all').where('userkey', '==', user.key).where('status', '==', true).where('receivedate', '>=', lastdate).get().then((result) => {
      result.forEach((doc) => {
        if(doc.data().receivedate)
        history.push({
          key: doc.id,
          type: 'พันธมิตร',
          createdate: doc.data().receivedate,
          createdateShow: moment(doc.data().receivedate, 'YYYY-MM-DD HH:mm:ss').format('DD MMM,YY HH:ss'),
          qty: doc.data().total,
          status: 'ดำเนินการแล้ว'
        })
      })
    })
    await firebase.firestore().collection('cashback_all').where('userkey', '==', user.key).where('status', '==', true).where('receivedate', '>=', lastdate).get().then((result) => {
      result.forEach((doc) => {
        if(doc.data().receivedate)
        history.push({
          key: doc.id,
          type: 'คืนยอดเล่น',
          createdate: doc.data().receivedate,
          createdateShow: moment(doc.data().receivedate, 'YYYY-MM-DD HH:mm:ss').format('DD MMM,YY HH:ss'),
          qty: doc.data().total,
          status: 'ดำเนินการแล้ว'
        })
      })
    })

    this.history = _.orderBy(history, ['createdate'], ['desc'])
    this.$store.commit('user/SET_LOADING', false)
  }
}
</script>
<style scoped>
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);

h1 {
  font-size:3em;
  font-weight: 300;
  line-height:1em;
  text-align: center;
  color: #4DC3FA;
}

h2 {
  font-size:1em;
  font-weight: 300;
  text-align: center;
  display: block;
  line-height:1em;
  padding-bottom: 2em;
  color: #FB667A;
}

h2 a {
  font-weight: 700;
  text-transform: uppercase;
  color: #FB667A;
  text-decoration: none;
}

.blue { color: #185875; }
.yellow { color: #FFF842; }

.tablecontainer th h1 {
  font-family: 'Kanit';
	  font-weight: bold;
	  font-size: 1.1em;
    text-align: left;
    color: #fff;
}

.tablecontainer td {
  font-family: 'Kanit';
	  font-weight: normal;
	  font-size: 1em;
  -webkit-box-shadow: 0 2px 2px -2px #0E1119;
	   -moz-box-shadow: 0 2px 2px -2px #0E1119;
	        box-shadow: 0 2px 2px -2px #0E1119;
}

.tablecontainer {
  text-align: left;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 0 0 8em 0;
}

.tablecontainer td, .tablecontainer th {
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left:2%;
}

/* Background-color of the odd rows */
.tablecontainer tr:nth-child(odd) {
  background-color: #323C50;
}

/* Background-color of the even rows */
.tablecontainer tr:nth-child(even) {
  background-color: #2C3446;
}

.tablecontainer th {
  background-color: #2462fc;
}

.tablecontainer tr:hover {
  background-color: #464A52;
  -webkit-box-shadow: 0 6px 6px -6px #0E1119;
  -moz-box-shadow: 0 6px 6px -6px #0E1119;
      box-shadow: 0 6px 6px -6px #0E1119;
}

.tablecontainer td:hover {
  background-color: #FFF842;
  color: #403E10;
  font-weight: bold;

  box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
  transform: translate3d(6px, -6px, 0);

  transition-delay: 0s;
	  transition-duration: 0.4s;
	  transition-property: all;
  transition-timing-function: line;
}

/* @media (max-width: 800px) {
.tablecontainer td:nth-child(4),
.tablecontainer th:nth-child(4) { display: none; }
} */
@media (max-width: 500px) {
    .x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-img-category {
        width: 45px;
        height: 45px;
    }
}
@media (max-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: .8rem;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent {
    display: flex;
}
}
@media (max-width: 990px) {
#main_content_recommend .x-category-index, #main_content_income .x-category-index {
    padding-top: 10px;
}
}

</style>
